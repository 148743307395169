import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Seo } from "../components/Seo/Seo"
import {
  Navigation,
  Blog,
  Footer,
  Hero,
  Breadcrumb,
} from "../components/Marketing"

import { CategoryEntity } from "../entities/CategoryEntity"
import { ArticleEntity } from "../entities/ArticleEntity"
import { getImage } from "gatsby-plugin-image"
import { Separator } from "../components/article"

const CategoryPage: React.FC<PageProps<
  any,
  {
    articles: ArticleEntity[]
    category: CategoryEntity
    illustration: any
  }
>> = props => {
  const { articles, category } = props.pageContext
  const breadcrumbs = [{ label: category.id, url: category.url }]
  const data = useStaticQuery(graphql`
    query GetCategoryImage {
      histoire: file(relativePath: { eq: "landing/histoire.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 600
            quality: 80
          )
        }
      }
      apprendre: file(relativePath: { eq: "landing/apprendre.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 600
            quality: 80
          )
        }
      }
    }
  `)

  const illustration =
    category.id === "histoire"
      ? getImage(data.histoire)
      : getImage(data.apprendre)

  return (
    <>
      <Seo
        title={category.meta.title}
        description={category.meta.description}
        breadcrumbs={breadcrumbs}
        image={illustration.images.fallback.src}
        ads
      />
      <Navigation />
      <Hero
        illustration={illustration}
        theme={{ as: "nav", value: <Breadcrumb items={breadcrumbs} /> }}
        title={{
          as: "h1",
          value: category.title,
          raw: true,
        }}
        description={{
          value: category.description,
          raw: true,
        }}
      />
      <Separator />
      <Blog
        title={{
          as: "h2",
          value: (
            <>
              Tous les articles sur le{" "}
              <span className="text-green-600">haïku</span>
              <br></br>
              dans la catégorie{" "}
              <span className="text-green-600">{category.id}</span>
            </>
          ),
        }}
        description={
          <>
            Nous publions régulièrement des articles autour de l'apprentissage
            du haïku. Abonnez-vous sur les réseaux sociaux ou activez les
            notifications pour être notifié.
          </>
        }
        articles={articles.map(article => ({
          title: article.display__title,
          description: article.seo__description,
          author: article.author,
          id: article.url,
          url: article.url,
          image: article.thumb,
          category: article.categories[0].name,
          date: article.created_at,
          reading: article.reading,
        }))}
      />
      <Footer />
    </>
  )
}

export default CategoryPage
